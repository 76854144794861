import { createRouter, createWebHistory } from 'vue-router'
import Main from '@/views/Main.vue'

const routes = [
  {
    path: '/',
    name: 'main',
    component: Main
  },
  {
    path: '/reservation',
    name: 'reservation',
    component: () => import('@/views/Reservation.vue')
  },
  {
    path: '/account',
    name: 'account',
    component: () => import('@/views/Account.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
